<script>

    import appConfig from "@/app.config";
    import Paginate from "@/components/pagination";
    import helper from '@/assets/helpers/helper';
    import JoinComp from "@/components/popups/joinComp";

    export default {
        page: {
            title: "My Competitions",
            meta: [{name: "description", content: appConfig.description}]
        },
        components: {JoinComp, Paginate},
        name: 'MyCompetitions',
        data() {
            return {
                title: "My Competitions",
                none:'none',
                current_page: 1,
                last_page_url: 6,
                isLoaded: false,
                currentSort:'name',
                currentSortDir:'ascending',
                competitions: [],


            };
        },

         created() {
           this.loadCompetitions(1)
        },
        methods:{
            async loadCompetitions(page) {
                let competitions = await helper.loadData('me/competitions', page);
                this.isLoaded = true
                if (competitions.data){
                    this.competitions = competitions.data;

                this.current_page = competitions.current_page
                this.last_page_url = competitions.last_page;
            }
            },
            sort:function(s) {
                //if s == current sort, reverse
                if(s === this.currentSort) {
                    this.currentSortDir = this.currentSortDir==='ascending'?'descending':'ascending';
                }
                this.currentSort = s;
            },
            changePage(page) {
                this.loadCompetitions(page)
            },
        },
        computed:{
            competitions_sorted:function() {
                let competitions = this.competitions;
                return competitions.sort((a,b) => {
                    let modifier = 1;
                    if(this.currentSortDir === 'descending') modifier = -1;
                    if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                    if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                    return 0;
                });
            }
        },
    };
</script>
<template>

        <div class="row">
            <div class="col-xl-10 m-auto">
                <div class="row">
                    <div class="col">
                        <div class="card">
                            <div class="card-body mb-3">
                                <div class="row">
                                    <div class="col-xl-9 text-center">
                                        <h4 class="card-title ml-4 mb-4">{{$t('profil_comp.title_tab')}}</h4>
                                    </div>
                                    <div class="col-xl-3 text-right">
                                        <button type="button" class="btn btn-sm btn-primary"  @click="$bvModal.show('modal-join-comp')" >{{$t('dashboard.comp.join_private')}}</button>
                                        <join-comp/>
                                    </div>
                                </div>
                                <p  v-if="competitions.length == 0 && isLoaded" class="text-center pt-2">
                                    {{$t('validation.no_data')}} <br/>

                                    <button  class="btn btn-primary my-2  btn-xl " @click="$router.push({name:'Competitions'})">{{$t('profil_comp.tab_empty')}}</button>

                                </p>
                                <div v-if="!isLoaded">
                                    <b-skeleton-table :rows="6" :columns="6"></b-skeleton-table>
                                    <b-skeleton></b-skeleton>
                                </div>

                                <table class=" tab table b-table text-center" role="table" v-if="competitions.length && isLoaded">
                                    <thead>
                                    <tr>
                                        <th scope="col" @click="sort('name')" role="columnheader"  :aria-sort="(currentSort == 'name')?currentSortDir:none">{{$t('profil_comp.name')}}</th>
                                        <th scope="col" @click="sort('begin_at')" role="columnheader" :aria-sort="(currentSort == 'begin_at')?currentSortDir:none">{{$t('profil_comp.start')}}</th>
                                        <th scope="col" @click="sort('finish_at')" role="columnheader" :aria-sort="(currentSort == 'finish_at')?currentSortDir:none">{{$t('profil_comp.end')}}</th>

                                        <th scope="col" @click="sort('challenges_count')" role="columnheader" :aria-sort="(currentSort == 'challenges_count')?currentSortDir:none">{{$t('profil_comp.challenges')}}</th>
                                        <th scope="col" @click="sort('teams_count')" role="columnheader" :aria-sort="(currentSort == 'teams_count')?currentSortDir:none">{{$t('profil_comp.teams')}}</th>
                                        <th scope="col" >{{$t('profil_comp.actions')}}</th>

                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="data of competitions_sorted" :key="data.id">
                                      <td :data-label="$t('profil_comp.name')" ><router-link class="h6 link"  :to="{name:'ProfilCompetition',params:{id:data.id}}">{{data.name}}</router-link></td>
                                        <td :data-label="$t('profil_comp.start')"> {{data.begin_at}}</td>
                                        <td :data-label="$t('profil_comp.end')"> {{data.finish_at}}</td>
                                        <td :data-label="$t('profil_comp.challenges')"> {{data.challenges_count}}</td>
                                        <td :data-label="$t('profil_comp.teams')">{{data.teams_count}}</td>
                                        <td :data-label="$t('profil_comp.actions') ">
                                                    <router-link type="button"
                                                            class="btn btn-primary mb-2 btn-sm btn-rounded" :to="{name:'ScoreBoardCompetition',params:{id:data.id}}" >{{$t('profil_comp.scoreboard')}}
                                                    </router-link>
                                                    <router-link type="button" class="btn mb-2 mx-2 btn-primary btn-sm btn-rounded" :to="{name:'statisticsCompetition',params:{id:data.id}}">
                                                        {{$t('profil_comp.statistics')}}
                                                    </router-link>
                                                    <router-link type="button"  v-if="data.type_time =='live'" class="btn mb-2 btn-primary btn-sm btn-rounded" :to="{name:'Competition',params:{id:data.id}}">
                                                        {{$t('profil_comp.challenges')}}
                                                    </router-link>


                                        </td>
                                    </tr>


                                    </tbody>
                                </table>

                            </div>
                            <div class="row  mb-4" v-if="competitions && competitions.length  && isLoaded">
                                <div class="col">
                                    <div class="text-center">
                                        <paginate :current_page=current_page :last_page_url=last_page_url
                                                  v-on:change-page="changePage"/>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </div>


        </div>

</template>
<style scoped>
    .check-nav-icon {
        font-size: 26px !important;
    }

    .challenge {
        cursor: pointer;
        transition: all .2s ease-in-out;
    }

    .challenge:hover {
        transform: scale(1.1);
        background-color: #34c38f;
    }

    .active {
        background-color: #556ee6;
    }




</style>